<script setup>
import { useMainStore } from '@/store';
import { useTheme } from 'vuetify'
const mainStore = useMainStore();
const cartItems = ref([]);
const avatar = ref();
const { locale, setLocale } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const currentLocale = computed(() => locale.value)
const navMenu = ref(false)
const theme = useTheme();
const Mode = ref(theme.global.name.value);

const toggleTheme = () => {
    theme.global.name.value = Mode.value === 'customDarkTheme' ? 'customLightTheme' : 'customDarkTheme';
    Mode.value = theme.global.name.value;
    localStorage.scheme === 'customLightTheme' ? localStorage.scheme = 'customDarkTheme' : localStorage.scheme = 'customLightTheme';
};
// onNuxtReady(() => {
//     const val = localStorage.getItem('scheme');
//     // Check if 'scheme' is found in localStorage
//     if (val === 'customDarkTheme' || val === 'customLightTheme') {
//         // If found, set the global theme name and Mode value accordingly
//         theme.global.name.value = Mode.value = val;
//     }
// });


onMounted(() => {

});

// get user avatar
const isUser = ref(true)
async function getavatar() {
    const supabase = useSupabaseClient()
    try {
        const { data, error } = await supabase.auth.getSession(); // get session status from local cookies
        avatar.value = data?.session ? data.session.user.identities[0].identity_data.avatar_url : null
        // displayname.value = data.session.user.identities[0].identity_data.first_name || data.session.user.identities[0].identity_data.full_name // Display registered username
        // get account type

    } catch (error) {
        // console.log(error);
    }
}

// set language 
const change = ((val) => {
    setLocale(val);
})

// handle log in and log out
const user = useSupabaseUser()
watch(user, () => {
    if (user.value) {
        getavatar();
        isUser.value = true
    } else {
        getavatar();
        isUser.value = false
    }
}, { immediate: true })
</script>
<template>
    <div>
        <v-app-bar :color="theme.global.current.value.dark ? 'surface' : 'grey-lighten-4'" :elevation="1" app>
            <nuxt-link to="/" aria-label="Home">
                <v-toolbar-title class="md:p-4">
                    <!-- <h1 class="font-bold text-xl">Alfa Store</h1> -->
                    <svg width="100%" height="80" xmlns="http://www.w3.org/2000/svg">
                        <image href="/mainiconm.svg" :class="theme.global.current.value.dark ? ' filter invert' : ''"
                            class="md:p-a" width="150" alt="logo" />
                    </svg>
                </v-toolbar-title>
            </nuxt-link>
            <v-spacer />
            <ClientOnly>
                <div v-if="$vuetify.display.mobile" class="flex w-fit">
                    <v-locale-provider :rtl="currentLocale == 'ar' ? true : false">
                        <nuxt-link v-if="isUser" to="/" aria-label="Home" class="mx-2 my-auto hover:underline">{{
                            $t('home')
                        }}</nuxt-link>

                        <v-menu transition="slide-y-transition" v-model="navMenu">
                            <template v-slot:activator="{ props }">
                                <v-btn name="nav" aria-label="nav" v-bind="props" :ripple="false"
                                    @click="navMenu != navMenu" class="mr-md-5 mr-1" icon>
                                    <v-icon>{{ navMenu ? ' mdi-chevron-up' : ' mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </template>
                            <v-list :bg-color="theme.global.current.value.dark ? 'surface' : 'grey-lighten-4'">
                                <v-list-item v-if="!isUser">
                                    <nuxt-link to="/"><v-btn variant="flat" class="w-full justify-start bg-transparent">
                                            <v-icon size="20" class="ml-2 mr-2">mdi-home</v-icon>
                                            {{ $t('home') }}
                                        </v-btn></nuxt-link>
                                </v-list-item>
                                <v-list-item>
                                    <nuxt-link to="/About"><v-btn variant="flat"
                                            class="w-full justify-start bg-transparent">
                                            <v-icon size="20" class="ml-2 mr-2">mdi-information</v-icon>
                                            {{ $t('about') }} </v-btn></nuxt-link>
                                </v-list-item>
                                <v-list-item>
                                    <nuxt-link to="/Administrative"><v-btn variant="flat"
                                            class="w-full justify-start bg-transparent">
                                            <v-icon size="20" class="ml-2 mr-2">mdi-account-group</v-icon>
                                            {{ $t('adminstartors') }} </v-btn></nuxt-link>
                                </v-list-item>
                                <v-list-item>
                                    <nuxt-link to="/Advisory"><v-btn variant="flat"
                                            class="w-full justify-start bg-transparent">
                                            <v-icon size="20" class="ml-2 mr-2">mdi-shield-crown</v-icon>
                                            {{ $t('counselors') }} </v-btn></nuxt-link>
                                </v-list-item>
                                <v-list-item>
                                    <nuxt-link to="/articles"><v-btn variant="flat"
                                            class="w-full justify-start bg-transparent">
                                            <v-icon size="20" class="ml-2 mr-2">mdi-text-box-edit</v-icon>
                                            {{ $t('articles') }} </v-btn></nuxt-link>
                                </v-list-item>
                                <v-list-item>
                                    <nuxt-link to="/pdfs"><v-btn variant="flat"
                                            class="w-full justify-start bg-transparent">
                                            <v-icon size="20" class="ml-2 mr-2">mdi-book-multiple</v-icon>
                                            {{ $t('references') }} </v-btn></nuxt-link>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-locale-provider>
                </div>
                <div v-else class="flex w-fit">
                    <v-locale-provider rtl>
                        <nuxt-link to="/About" class="mr-5 hover:underline w-fit" active-Class="opacity-50">
                            {{ $t('about') }}</nuxt-link>
                        <nuxt-link to="/Administrative" class="mr-5 w-fit hover:underline" active-Class="opacity-50">
                            {{ $t('adminstartors') }}</nuxt-link>
                        <nuxt-link to="/Advisory" class="mr-5 w-fit hover:underline" active-Class="opacity-50">
                            {{ $t('counselors') }}</nuxt-link>
                        <nuxt-link to="/pdfs" class="mr-5 w-fit hover:underline" active-Class="opacity-50">
                            {{ $t('references') }}</nuxt-link>

                        <nuxt-link :to="$route.path.includes('/articles') ? '/' : '/articles'"
                            class="mr-5 hover:underline">
                            {{ $route.path.includes('/articles') ? $t('home') : $t('articles') }}</nuxt-link>
                    </v-locale-provider>
                </div>
            </ClientOnly>
            <!--Account-->
            <div v-if="!isUser" class="w-fit mr-1">
                <v-btn v-if="Mode == 'customLightTheme'" variant="flat" class="w-full bg-transparent"
                    aria-label="ThemeDark" name="ThemeDark" @click="toggleTheme" icon>
                    <v-icon size="20" class="mr-2a">mdi-brightness-2</v-icon>
                </v-btn>
                <v-btn v-else class="w-full bg-transparent" aria-label="ThemeLight" name="ThemeLight" variant="flat"
                    @click="toggleTheme" icon>
                    <v-icon size="20" class="mr-2a">mdi-brightness-7</v-icon>
                </v-btn>
            </div>

            <v-menu transition="slide-y-transition">
                <template v-slot:activator="{ props }">

                    <v-btn v-bind="props" v-if="avatar" class="mr-md-5 mr-2" icon>
                        <v-avatar size="30"><v-img :src="avatar ? avatar : null"></v-img></v-avatar>
                    </v-btn>

                    <v-btn v-bind="props" v-else v-if="isUser" class="mr-md-5 mr-2" icon><v-icon
                            size="25">mdi-account-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="!isUser" to="/user/account" aria-label="Login" name="Login" class="mr-md-5 mr-2"
                        variant="tonal"><v-icon>mdi-login</v-icon></v-btn>

                </template>
                <v-list :bg-color="theme.global.current.value.dark ? 'surface' : 'grey-lighten-4'">
                    <!-- <v-list-item v-for="(item, i) in items" :key="i"> -->
                    <v-list-item>
                        <nuxt-link to="/"><v-btn variant="flat" class="w-full justify-start bg-transparent">
                                <v-icon size="20" class="mr-2">mdi-home</v-icon>
                                الرئيسية
                            </v-btn></nuxt-link>
                    </v-list-item>
                    <v-list-item>
                        <nuxt-link to="/user/account"><v-btn variant="flat" class="w-full justify-start bg-transparent">
                                <v-icon size="20" class="mr-2">mdi-cog</v-icon>
                                ادارة الحساب
                            </v-btn></nuxt-link>
                    </v-list-item>
                    <v-list-item :disabled="true">
                        <nuxt-link to="/courses"><v-btn variant="flat" class="w-full justify-start bg-transparent">
                                <v-icon size="20" class="mr-2">mdi-laptop-account</v-icon>
                                ادارة دوراتي<p class="ml-2 px-1 rounded-md bg-black">قريبا</p>
                            </v-btn>

                        </nuxt-link>
                    </v-list-item>
                    <v-list-item>
                        <nuxt-link to="/support">
                            <v-btn variant="flat" class="mr-2a bg-transparent">
                                <v-icon size="20" class=" mr-2">mdi-face-agent</v-icon>
                                المساعدة والدعم
                            </v-btn>
                        </nuxt-link>
                    </v-list-item>
                    <v-list-item>
                        <v-btn v-if="Mode == 'customLightTheme'" variant="flat" class="w-full bg-transparent"
                            name="dark" @click="toggleTheme">
                            <v-icon size="20" class="mr-2">mdi-brightness-2</v-icon>
                            الوضع الداكن
                        </v-btn>
                        <v-btn v-else class="w-full bg-transparent" name="light" variant="flat" @click="toggleTheme">
                            <v-icon size="20" class="mr-2">mdi-brightness-7</v-icon>
                            الوضع الفاتح
                        </v-btn>
                    </v-list-item>
                    <!-- </v-list-item> -->
                </v-list>
            </v-menu>
            <!--trans-->
            <v-menu transition="slide-y-transition">
                <template v-slot:activator="{ props }">
                    <v-btn name="theme" v-bind="props" class="mr-2 text-subtitle-1" variant="text" icon>{{
                        currentLocale.toUpperCase() }}</v-btn>
                </template>
                <v-list :bg-color="theme.global.current.value.dark ? 'surface' : 'grey-lighten-4'">
                    <v-list-item> <button @click="change('en')" class="w-full flex justify-start bg-transparent">English
                            <!-- <p class="ml-2 px-1 rounded-sm bg-black">Soon</p> -->
                        </button>
                    </v-list-item>
                    <v-list-item> <button @click="change('ar')"
                            class="w-full justify-start bg-transparent">العربية</button>
                    </v-list-item>
                </v-list>
            </v-menu>


        </v-app-bar>
    </div>
</template>