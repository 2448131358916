import { default as indexif8xBg25EOMeta } from "/opt/build/repo/pages/about/index.vue?macro=true";
import { default as AdministrativeBWwmpAF52pMeta } from "/opt/build/repo/pages/Administrative.vue?macro=true";
import { default as Advisoryl00z1uv1bGMeta } from "/opt/build/repo/pages/Advisory.vue?macro=true";
import { default as _91id_old_93zKPamddMbiMeta } from "/opt/build/repo/pages/articles/[id_old].vue?macro=true";
import { default as _91title_93oRwQXMmhAJMeta } from "/opt/build/repo/pages/articles/[id]/[title].vue?macro=true";
import { default as indexQ2Wa6y1KPhMeta } from "/opt/build/repo/pages/articles/index.vue?macro=true";
import { default as indexoHeXFHRpi3Meta } from "/opt/build/repo/pages/collabs/index.vue?macro=true";
import { default as indexJY9exXV4NhMeta } from "/opt/build/repo/pages/courses/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as index9WruYoq02MMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as oneClickLoginRU5P3aMr9XMeta } from "/opt/build/repo/pages/login/oneClickLogin.vue?macro=true";
import { default as requestPasswordResetMHpAJ81HaMMeta } from "/opt/build/repo/pages/login/requestPasswordReset.vue?macro=true";
import { default as resetPassword7xbb9LGtRrMeta } from "/opt/build/repo/pages/login/resetPassword.vue?macro=true";
import { default as verifyOTPR8tXs5gf1uMeta } from "/opt/build/repo/pages/login/verifyOTP.vue?macro=true";
import { default as _91title_93QXuLywdvtoMeta } from "/opt/build/repo/pages/pdfs/[id]/[title].vue?macro=true";
import { default as indextQwkKGmhRiMeta } from "/opt/build/repo/pages/pdfs/index.vue?macro=true";
import { default as signupQ6T3vUFXXDMeta } from "/opt/build/repo/pages/signup.vue?macro=true";
import { default as indexpONyrZBBY2Meta } from "/opt/build/repo/pages/support/index.vue?macro=true";
import { default as accountOyJ5QNbknrMeta } from "/opt/build/repo/pages/user/account.vue?macro=true";
import { default as indexuu6yzSj8DAMeta } from "/opt/build/repo/pages/user/index.vue?macro=true";
import { default as _91invoice_93BPEvdhyIVWMeta } from "/opt/build/repo/pages/user/orders/[invoice].vue?macro=true";
import { default as _91orderId_93KUHzvmwnCaMeta } from "/opt/build/repo/pages/user/orders/[orderId].vue?macro=true";
import { default as indexF17oWZXNlPMeta } from "/opt/build/repo/pages/user/orders/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/build/repo/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "Administrative",
    path: "/Administrative",
    component: () => import("/opt/build/repo/pages/Administrative.vue").then(m => m.default || m)
  },
  {
    name: "Advisory",
    path: "/Advisory",
    component: () => import("/opt/build/repo/pages/Advisory.vue").then(m => m.default || m)
  },
  {
    name: "articles-id_old",
    path: "/articles/:id_old()",
    component: () => import("/opt/build/repo/pages/articles/[id_old].vue").then(m => m.default || m)
  },
  {
    name: "articles-id-title",
    path: "/articles/:id()/:title()",
    component: () => import("/opt/build/repo/pages/articles/[id]/[title].vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/opt/build/repo/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "collabs",
    path: "/collabs",
    component: () => import("/opt/build/repo/pages/collabs/index.vue").then(m => m.default || m)
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/opt/build/repo/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/build/repo/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-oneClickLogin",
    path: "/login/oneClickLogin",
    component: () => import("/opt/build/repo/pages/login/oneClickLogin.vue").then(m => m.default || m)
  },
  {
    name: "login-requestPasswordReset",
    path: "/login/requestPasswordReset",
    component: () => import("/opt/build/repo/pages/login/requestPasswordReset.vue").then(m => m.default || m)
  },
  {
    name: "login-resetPassword",
    path: "/login/resetPassword",
    component: () => import("/opt/build/repo/pages/login/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "login-verifyOTP",
    path: "/login/verifyOTP",
    component: () => import("/opt/build/repo/pages/login/verifyOTP.vue").then(m => m.default || m)
  },
  {
    name: "pdfs-id-title",
    path: "/pdfs/:id()/:title()",
    component: () => import("/opt/build/repo/pages/pdfs/[id]/[title].vue").then(m => m.default || m)
  },
  {
    name: "pdfs",
    path: "/pdfs",
    component: () => import("/opt/build/repo/pages/pdfs/index.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/opt/build/repo/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/opt/build/repo/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "user-account",
    path: "/user/account",
    component: () => import("/opt/build/repo/pages/user/account.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/opt/build/repo/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-orders-invoice",
    path: "/user/orders/:invoice()",
    component: () => import("/opt/build/repo/pages/user/orders/[invoice].vue").then(m => m.default || m)
  },
  {
    name: "user-orders-orderId",
    path: "/user/orders/:orderId()",
    component: () => import("/opt/build/repo/pages/user/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "user-orders",
    path: "/user/orders",
    component: () => import("/opt/build/repo/pages/user/orders/index.vue").then(m => m.default || m)
  }
]